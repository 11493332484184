<script>
import { globalStore } from '../main.js'
import axios from 'axios'
import DrillzLogoDark from '../assets/img/DZ_logo_black.png'
import DrillzLogoLight from '../assets/img/DZ_logo_white.png'
import DrillzToken3D from '../assets/img/Drillz_token_3D.png'
import NYESpinner from '../assets/img/NYE-spinner.png'
import LinkedWalletsBox from '../components/LinkedWalletsBox.vue'
import TwitterHandleBox from '../components/TwitterHandleBox.vue'
import DiscordRolesBox from '../components/DiscordRolesBox.vue'
import TokenBalanceGraph from '../components/TokenBalanceGraph.vue'
import CollectionGrid from '../components/CollectionGrid.vue'

import { TransactionLib } from '../plugins/transactionLib.js'

export default {
  name: 'HomeView',

  components: {
    LinkedWalletsBox,
    TwitterHandleBox,
    DiscordRolesBox,
    TokenBalanceGraph,
    CollectionGrid,
  },

  data() {
    return {
      DrillzLogoDark, DrillzLogoLight, DrillzToken3D, NYESpinner,

      selectedPolicyId: 0,
      showAllCollections: false,

      policies: null,
      numberOfItems: {},

      selectedTrophyBoard: 0,
      showAllTrophyBoards: false,
      trophyBoards: {
        'trophy-board-1': { name: "Artistic Missions", },
        'trophy-board-2': { name: "Logistic Missions", },
        'trophy-board-3': { name: "Badges", },
      },
      trophyCount: {},

      estimatedEarnings: null,
      floorPrices: {
        0: {
          volume: 3432000,
          owners: 2026,
          floorPrice: null,
          description: '<ul><li>The Mandrillz have been minted in the last days of Feb 2022 - A collection of 8888 unique NFTs, sold out in 15 min</li><li>Our self made mint machine provided one of the smoothest drops to this point</li><li>Months of hard work by @seppow86 and Brillo resulted in what is now called The Mandrillz</li></ul>',
        },
        1: {
          volume: 179000,
          owners: 755,
          floorPrice: null,
          description: '<ul><li>5000 unique Companions with hundreds of handmade assets</li><li>A never seen before whitelist system with our meteorite splinters</li><li>5 different skills weaved into the metadata to be used different projects of Drillz City</li></ul>',
        },
        2: {
          volume: 58500,
          owners: 1135,
          floorPrice: null,
          description: 'Mint NFT 6-Packs DIRECTLY into your slick new Mandrillz Worldcup trading card album in the new Mandrillz Portal. Collect all the international teams, get legendary Mandrillz, and collect the rare DREAMTEAM – a collab with <b>12 amazing Cardano NFT projects! Including</b> Fortgotten, Veggiemates, Mutants, Ted Nation, Wolves, Seagulls, ClayPez and Ragnarok Reptiles!!',
        },
        3: {
          volume: 14400,
          owners: 241,
          floorPrice: null,
          description: 'The Mandrillz Skullz art spinoff is a side project, which is directed to art loving collectors of The Mandrillz. Holders with special roles will receive them from time to time or they can take part in raffles. Each NFT is numbered to turn it into a true unique NFT. Are you a collector? Try to get \'em all.',
        },
        4: {
          volume: 38100,
          owners: 112,
          floorPrice: null,
          description: 'A special airdrop drop was The Mandrillz OG Bar NFT. It came attached with a special perk. 50 out of 150 OG Bar holders will win every following The Mandrillz™ - Drillz City Gallery Drop by raffle. <br /><br />Stay tuned for more perks with future events because the OG Bar holders will always be remembered.',
        },
        5: {
          volume: -1,
          owners: -1,
          description: '',
        },
        6: {
          volume: -1,
          owners: -1,
          floorPrice: null,
          description: 'The Mandrillz - One Of Us collection consists of exclusive 1 of 1 Mandrillz and Companions. All pieces of this art based collection will be given to The Mandrillz community.',
        },
        7: {
          volume: -1,
          owners: -1,
          floorPrice: null,
          description: 'The Mandrillz Gallery is the place for various Drillz related Art Dropz going forward. This diverse collection will include OG Dropz,  Special Mandrillz Missions NFT\'s, Cleon Family Dropz and other discord role related specials, Artist Collabs and more.',
        },
      },

      useBarChart: true,
      fillChart: false,
      lineWidth: 2,

      mintableItems: null,
      mintableStates: null,
      fetchingMintableItems: false,
      showError: false,
      errorMessage: null,

      mintId: null,
      useLedger: false,

      tx: null,
      lastTxId: null,
      lastMintedId: null,
      mintStatus: null,
      successMessage: null,
      mintedSuccessfully: false,

      fetchingTokenBalance: false,

      loading: false,
      checkingStatus: false,
      intervalId: null,

      checkingTokenPacks: false,
      claimingTokenPacks: false,
      claimedSuccessfully: false,
      isStarterPackClaimable: false,
      showPackError: false,
      packErrorMessage: null,

      scrollOptions: {
        duration: 700,
        offset: 60,
        easing: 'easeInOutCubic',
      },
    }
  },

  computed: {
    // wallet address is what the Mandrillz backend API needs
    walletAddress() {
      return globalStore.wallet.walletAddress
    },

    walletAddresses() {
      return this.profile && this.profile.wallets ? this.profile.wallets.map(a => a.stakeAddressHash) : null
    },

    // walletApi is the local wallet browser extension reference (e.g. window.cardano.eternl)
    walletApi() {
      return globalStore.wallet.ref.enableResult
    },

    // balance is the current amount of Drillz tokens, not ADA
    drillzBalance() {
      return globalStore.wallet.drillzBalance
    },

    fetchingLoginState() {
      return globalStore.account.loading
    },

    // balance is the current amount of Drillz tokens, not ADA
    totalDrillzBalance() {
      return globalStore.account.totalDrillzBalance
    },
    
    // holds the profile object when logged in with Discord
    profile: {
      get: function() {
        return globalStore.account.profile
      },
      set: function(newValue) {
        globalStore.account.profile = newValue
      }
    },

    isCurrentWalletLinked() {
      return globalStore.account.profile && globalStore.account.profile.wallets && globalStore.account.profile.wallets.map(a => a.stakeAddressHash).includes(globalStore.wallet.walletAddress)
    },

    apiBaseURL() {
      return globalStore.endpoints.apiBaseURL
    },

    mintApiURL() {
      return globalStore.endpoints.apiBaseURL + '/mint'
    },

    walletApiURL() {
      if (!this.walletAddress) {
        return null
      }
      return globalStore.endpoints.apiBaseURL + '/wallet/' + this.walletAddress
    },

    walletCollectionURLPrefix() {
      return globalStore.endpoints.apiBaseURL + '/wallet/'
    },

    selectedPolicy() {
      return this.policies && this.selectedPolicyId >= 0 ? Object.keys(this.policies)[this.selectedPolicyId] : null
    },

    policyVolume() {
      return this.floorPrices[this.selectedPolicyId].floorPrice !== null && this.floorPrices[this.selectedPolicyId].volume ? this.floorPrices[this.selectedPolicyId].volume : null
    },

    policyOwners() {
      return this.floorPrices[this.selectedPolicyId].floorPrice !== null && this.floorPrices[this.selectedPolicyId].owners ? this.floorPrices[this.selectedPolicyId].owners : null
    },

    policyFloorPrice() {
      return this.floorPrices[this.selectedPolicyId].floorPrice
    },

    policyName() {
      return this.policies && this.selectedPolicyId >= 0 ? this.policies[this.selectedPolicy].name : ''
    },

    policyDescription() {
      return this.floorPrices[this.selectedPolicyId].description
    },

    itemCount() {
      return this.numberOfItems
    },

    showStarterPacks() {
      return this.isStarterPackClaimable && this.isStarterPackClaimable.length > 0
      || this.showPackError || this.claimingTokenPacks || this.claimedSuccessfully
    }
  },

  watch: {
    walletAddress() {
      this.fetchBalance()
    },

    selectedPolicy() {
      if (!this.selectedPolicy) {
        return
      }

      if (this.floorPrices[this.selectedPolicyId].floorPrice === null) {
        this.fetchFloorPrice()
      }
    },

    profile() {
      if (this.profile) {
        this.checkStarterPacks()
        this.fetchMintableItems()
      }
    },

    useBarChart() {
      localStorage.setItem('themandrillz-last-portal-chart-type', this.useBarChart ? 'bar' : 'trend')
    },
  },

  created() {
    this.scanPolicies()

    let lastChartType = localStorage.getItem('themandrillz-last-portal-chart-type')
    if (lastChartType) {
      this.useBarChart = lastChartType == 'bar'
    }
  },

  methods: {
    scanPolicies() {
      if (!globalStore.policies) {
        return
      }

      // make a full copy to remove entries from the original global object 
      let copyPolicies = JSON.parse(JSON.stringify(globalStore.policies))
      // remove the specific placeholder entry to prevent from displaying
      delete copyPolicies['placeholder']
      console.debug("PortalView new policies:", copyPolicies)

      // init
      Object.keys(copyPolicies).forEach((policyId, index) => {
        this.numberOfItems[index] = 0
      })

      this.policies = copyPolicies
    },

    saveNumberOfItems(amount, policy) {
      console.debug("PortalView saveNumberOfItems", "policy:", policy, "amount:", amount)
      this.numberOfItems[Object.keys(this.policies).indexOf(policy)] = amount
    },

    async fetchBalance() {
      this.fetchingTokenBalance = true
      this.errorMessage = null
      this.showError = false

      if (!this.walletAddress) {
        console.log("PortalView cannot fetch balance for wallet address:", this.walletAddress)
        this.fetchingTokenBalance = false
        return
      }

      try {
        const res = await fetch(`${this.apiBaseURL}/wallet/${this.walletAddress}`)

        if (!res.ok) {
          const message = `${res.status} - ${res.statusText}`;
          throw new Error(message);
        }

        const rawBalance = await res.json()

        console.debug("PortalView got balance response from API:", rawBalance)

        if (rawBalance && rawBalance.stakeAddressHash && (rawBalance.drillzToken === 0 || rawBalance.drillzToken > 0)) {
          if (this.walletAddress == rawBalance.stakeAddressHash) {
            globalStore.wallet.drillzBalance = rawBalance.drillzToken
          }

          console.log("Received Drillz balance:", rawBalance.drillzToken)
        }
      } catch (err) {
        console.warn("Fetching Drillz balance failed:", err.message)
        this.errorMessage = err.message
        this.showError = true
      } finally {
        this.fetchingTokenBalance = false
      }
    },

    fetchFloorPrice() {
      if (!this.selectedPolicy)
        return

      axios
        .get(this.apiBaseURL + '/market/' + this.selectedPolicy + '/floorPrice')
        .then((response) => {
          console.debug("PortalView fetch floor price result:", response)

          this.floorPrices[this.selectedPolicyId].floorPrice = Math.round(parseFloat(response.data.floor) / 10000000) * 10
        })
        .catch((err) => {
          console.debug("PortalView fetch floor price error:", err)

          switch (globalStore.wallet.name) {
            case 'flint': this.errorMessage = err.response?.data?.message || err.message || err.info || err
              break;
            default: this.errorMessage = err.response?.data?.message || err.message || err;
              break;
          }
          this.errorMessage = "Not successful: " + this.errorMessage.replace('tx', 'transaction')
          this.showError = true
        })
        .finally(() => {
        });
    },

    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.debug('Fallback copying wallet address was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy wallet address', err);
      }

      document.body.removeChild(textArea);
    },

    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.debug('Copying wallet address to clipboard was successful');
      }, function (err) {
        console.error('Could not copy wallet address: ', err);
      });
    },

    copyPolicyId() {
      console.log("Policy ID to copy:", this.selectedPolicy)
      this.copyTextToClipboard(this.selectedPolicy)
    },

    requestStarterPackClaim() {
      console.debug("PortalView requesting token packs claim")
      this.claimingTokenPacks = true
      this.claimedSuccessfully = false
      this.packErrorMessage = null
      this.showPackError = false

      axios
        .post(this.apiBaseURL + '/tokenbag/claim',
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          })
        .then((response) => {
          console.debug("PortalView token pack claim response:", response)
          this.claimedSuccessfully = response && response.data ? response.data : false

          if (this.claimedSuccessfully)
            this.successMessage = "Claimed successfully (received tokens: " + response.data.map(a => a.receivedTokens).reduce((a, b) => a + b, 0) + ")"

          this.checkStarterPacks()
        })
        .catch((err) => {
          console.error("PortalView token pack claim error:", err)

          this.packErrorMessage = err.response?.data?.message || err.message || err
          if (this.packErrorMessage) {
            this.packErrorMessage = this.packErrorMessage.replace('tx', 'transaction')
          }
          this.packErrorMessage = "Not successful: " + this.packErrorMessage
          this.showPackError = true
        })
        .finally(() => {
          this.claimingTokenPacks = false
        });
    },

    // requestStarterPackClaim() {
    //   this.claimingTokenPacks = true
    //   this.checkingStatus = false
    //   this.tx = null
    //   this.lastTxId = null
    //   this.mintStatus = null
    //   this.successMessage = null
    //   this.claimedSuccessfully = false
    //   this.packErrorMessage = null
    //   this.showPackError = false

    //   if (!this.checkIsWalletAddressOkay(true))
    //     return

    //   axios
    //     .post(this.mintApiURL + '/buildTransferTransaction', {
    //       rewardAddress: this.walletAddress
    //     })
    //     .then((response) => {
    //       console.debug("PortalView requestStarterPackClaim Step 1:", response)

    //       this.startCheckingStatus()

    //       this.tx = TransactionLib.parseTransaction(response.data.cborHex)
    //       return this.walletApi.signTx(response.data.cborHex, false)
    //     })
    //     .then((witnessCbor) => {
    //       console.debug("PortalView requestStarterPackClaim Step 2:", witnessCbor)
    //       let witness = TransactionLib.parseWitnessSet(witnessCbor)
    //       this.tx = TransactionLib.addWitness(this.tx, witness)

    //       // we send the prepared Cardano transaction to the Backend to complete it
    //       // (and possibly making additional processing)
    //       return axios
    //         .post(this.mintApiURL + '/' + this.walletAddress + '/submitTransaction', {
    //           cbor: TransactionLib.serializeTransaction(this.tx)
    //         })
    //       // alternatively we could also request the local browser extension's Cardano object
    //       // to submit the transaction to the Blockchain
    //       //return this.walletApi.submitTx(TransactionLib.serializeTransaction(this.tx));
    //     })
    //     .then((response) => {
    //       console.debug("PortalView requestStarterPackClaim Step 3:", response)
    //       if (response) {
    //         this.lastTxId = response.data ? response.data : response
    //       }
    //     })
    //     .catch((err) => {
    //       console.error("PortalView requestStarterPackClaim error:", err)

    //       this.errorMessage = err.response?.data?.message || err.message || err
    //       if (this.errorMessage) {
    //         this.errorMessage = this.errorMessage.replace('tx', 'transaction')
    //       }
    //       this.errorMessage = "Not successful: " + this.errorMessage
    //       this.showError = true

    //       this.checkingStatus = false
    //       clearInterval(this.intervalId)

    //       this.cancelMintingStatus()
    //     })
    //     .finally(() => {
    //       console.debug("PortalView requestStarterPackClaim completed")
    //       this.claimingTokenPacks = false
    //     });
    // },

    requestMinting(itemId) {
      this.loading = true
      this.checkingStatus = false
      this.tx = null
      this.lastTxId = null
      this.lastMintedId = null
      this.mintStatus = null
      this.successMessage = null
      this.mintedSuccessfully = false
      this.errorMessage = null
      this.showError = false

      if (!this.checkIsWalletAddressOkay(true))
        return

      axios
        .post(this.mintApiURL + '/buildTransferTransaction', {
          mintId: itemId,
          rewardAddressHash: this.walletAddress
        },
        {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => {
          console.debug("PortalView requestMinting Step 1:", response)

          this.startCheckingStatus(itemId)

          this.tx = TransactionLib.parseTransaction(response.data.cborHex)
          return this.walletApi.signTx(response.data.cborHex, false)
        })
        .then((witnessCbor) => {
          console.debug("PortalView requestMinting Step 2:", witnessCbor)
          let witness = TransactionLib.parseWitnessSet(witnessCbor)
          this.tx = TransactionLib.addWitness(this.tx, witness)

          // we send the prepared Cardano transaction to the Backend to complete it
          // (and possibly making additional processing)
          return axios
            .post(this.mintApiURL + '/' + this.walletAddress + '/submitTransaction',
            {
              cbor: TransactionLib.serializeTransaction(this.tx)
            },
            {
              headers: { 'X-Requested-With': 'XMLHttpRequest' },
            })
          // alternatively we could also request the local browser extension's Cardano object
          // to submit the transaction to the Blockchain
          //return this.walletApi.submitTx(TransactionLib.serializeTransaction(this.tx));
        })
        .then((response) => {
          console.debug("PortalView requestMinting Step 3:", response)
          if (response) {
            this.lastTxId = response.data ? response.data : response
          }
        })
        .catch((err) => {
          console.error("PortalView requestMinting error:", err)

          this.errorMessage = err.response?.data?.message || err.message || err
          if (this.errorMessage) {
            this.errorMessage = this.errorMessage.replace('tx', 'transaction')
          }
          this.errorMessage = "Not successful: " + this.errorMessage
          this.showError = true

          this.checkingStatus = false
          clearInterval(this.intervalId)

          this.cancelMintingStatus()
        })
        .finally(() => {
          console.debug("PortalView requestMinting completed")
          this.loading = false
        });
    },

    checkIsWalletAddressOkay(setErrorMessage) {
      if (!this.walletAddress) {
        console.log("PortalView: no wallet address:", this.walletAddress)

        if (setErrorMessage) {
          this.errorMessage = "No wallet is connected"
          this.showError = true
        }

        this.loading = false
        this.checkingStatus = false
        return false
      }
      return true
    },

    checkStarterPacks() {
      console.debug("PortalView checking token packs claimable status")
      this.checkingTokenPacks = true
      this.packErrorMessage = null
      this.showPackError = false

      axios
        .get(this.apiBaseURL + '/tokenbag/available',
          {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          })
        .then((response) => {
          console.debug("PortalView token pack claimable response:", response)
          this.isStarterPackClaimable = response && response.data ? response.data : false
        })
        .catch((err) => {
          console.error("PortalView checkStarterPacks error:", err)

          this.packErrorMessage = err.response?.data?.message || err.message || err
          if (this.packErrorMessage) {
            this.packErrorMessage = this.packErrorMessage.replace('tx', 'transaction')
          }
          this.packErrorMessage = "Not successful: " + this.packErrorMessage
          this.showPackError = true
        })
        .finally(() => {
          this.checkingTokenPacks = false
        });
    },

    fetchMintableItems() {
      console.debug("PortalView fetching available mints")
      this.fetchingMintableItems = true
      this.errorMessage = null
      this.showError = false

      axios
        .get(this.mintApiURL)
        .then((response) => {
          console.debug("PortalView available mints check response:", response)
          this.mintableItems = null

          if (!response) {
            throw new Error("no response")
          }
          if (!Array.isArray(response.data)) {
            console.error("PortalView available mints check error, not an array")
            return
          }

          this.mintableItems = response.data
          this.mintableStates = []

          if (this.profile) {
            this.mintableItems.forEach((item) => {
              this.mintableStates[item.id] = {
                mintable: false,
                reason: null
              }

              this.checkIsMintable(item.id)
            })
          }
        })
        .catch((err) => {
          console.error("PortalView available mints check error:", err)

          this.errorMessage = err.response?.data?.message || err.message || err
          if (this.errorMessage) {
            this.errorMessage = this.errorMessage.replace('tx', 'transaction')
          }
          this.errorMessage = "Not successful: " + this.errorMessage
          this.showError = true
        })
        .finally(() => {
          this.fetchingMintableItems = false
        });
    },

    checkIsMintable(itemId) {
      console.debug("PortalView checking mintable items")
      this.fetchingMintableItems = true
      this.errorMessage = null
      this.showError = false

      axios
        .get(this.mintApiURL + '/' + itemId + '/mintable')
        .then((response) => {
          console.debug("PortalView mintable check response:", response)
          if (response && response.data) {
            this.mintableStates[itemId].mintable = response.data.mintable
            this.mintableStates[itemId].reason = response.data.reason
          }
        })
        .catch((err) => {
          console.error("PortalView available mints check error:", err)

          this.errorMessage = err.response?.data?.message || err.message || err
          if (this.errorMessage) {
            this.errorMessage = this.errorMessage.replace('tx', 'transaction')
          }
          this.errorMessage = "Not successful: " + this.errorMessage
          this.showError = true
        })
        .finally(() => {
          this.fetchingMintableItems = false
        });
    },

    startCheckingStatus(itemId) {
      this.checkingStatus = true

      var self = this
      var item = itemId
      this.intervalId = setInterval(() => { self.checkStatus(self, item) }, 10000)
    },

    checkStatus(self, itemId) {
      axios
        .get(self.walletApiURL + '/status')
        .then((response) => {
          console.debug("PortalView checkStatus:", response)

          self.mintStatus = response.data?.status || 'Unknown status'

          if (response.data.finished == true) {
            console.debug("PortalView checkStatus finished=true -> stopping check interval", response)
            clearInterval(self.intervalId)

            self.checkingStatus = false
            self.successMessage = "Minting " + (self.mintableItems[itemId] ? " " + self.mintableItems[itemId].name : "") + "was successful. Please reload the page."
            self.mintedSuccessfully = true
            self.lastMintedId = itemId

            self.$emit('mintCompleted')
          }

          if (self.lastTxId != response.data.txId) {
            console.debug('PortalView check status: new transaction id received:', self.lastTxId, response.data.txId)

            self.lastTxId = response.data.txId
          }
        })
        .catch((err) => {
          console.warn("PortalView check status error:", err)

          self.checkingStatus = false

          self.errorMessage = err.response?.data?.message || err.message
          self.errorMessage = "Checking status failed: " + self.errorMessage + " (please refresh this page and check your collection in 1-2 minutes)"
          self.showError = true

          clearInterval(self.intervalId)
        })
        .finally(() => {
        });
    },

    cancelMintingStatus() {
      console.debug("PortalView cancelMintingStatus requested for last transaction id:", this.lastTxId)

      if (!this.checkIsWalletAddressOkay(true))
        return

      axios
        .post(this.walletApiURL + '/status/cancel')
        .then((response) => {
          console.debug("PortalView cancelMintingStatus:", response)
        })
        .catch((err) => {
          console.warn("PortalView cancelMintingStatus error:", err)
        })
        .finally(() => {
        });
    },

    startLinkingWallet() {
      console.debug("PortalView deciding which link method to take: useLedger:", this.useLedger)
      this.linkingWallet = true
      this.linkingErrorMessage = null
      this.showLinkingError = false
      this.linkedSuccessfully = false
      this.successMessage = null

      if (this.useLedger) {
        this.linkWalletWithLedger()
      } else {
        this.linkWallet()
      }
    },

    async linkWallet() {
      console.debug("PortalView linking current wallet to profile")

      axios
        .get(this.apiBaseURL + '/account/getWalletClaimMessage/' + this.walletAddress)
        .then((response) => {
          console.debug("PortalView linking wallet step 1:", response)

          return this.walletApi.signData(this.walletAddress, response.data)
        })
        .then((data) => {
          console.debug("PortalView linking wallet step 2:", data)

          // we send the signed data to the Backend to 1) validate it
          // and 2) add the wallet address to the Discord profile
          return axios
            .post(this.apiBaseURL + '/account/claimWallet', data)
        })
        .then((response) => {
          console.debug("PortalView linking wallet step 3:", response)
          return axios
            .get(this.apiBaseURL + '/account/userInfo',
              {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
              }
            )
        })
        .then((response) => {
          console.debug("PortalView linking wallet step 4:", response)
          // we try to recognize that a new wallet has been added compared to previous state
          let oldProfile = this.profile
          let newProfile = this.profile = response && response.data && response.data.id ? response.data : null

          if (!oldProfile || !newProfile) {
            throw new Error("profile could not be updated")
          }

          if ((!oldProfile.wallets && newProfile.wallets && newProfile.wallets.length > 0) ||
            oldProfile.wallets && newProfile.wallets && oldProfile.wallets.length < newProfile.wallets.length) {
            console.debug("PortalView linking wallet successful")
            this.successMessage = "Wallet linked successfully"
            this.linkedSuccessfully = true
          } else {
            throw new Error("wallets could not be updated")
          }
        })
        .catch((err) => {
          console.error("PortalView linking wallet error:", err)

          this.linkingErrorMessage = err.response?.data?.message || err.message || err

          this.linkingErrorMessage = "Not successful: " + this.linkingErrorMessage
          this.showLinkingError = true
        })
        .finally(() => {
          this.linkingWallet = false
        });
    },

    async linkWalletWithLedger() {
      console.debug("PortalView linking current wallet to profile with Ledger")

      axios
        .get(this.apiBaseURL + '/account/getWalletClaimMessage2/' + this.walletAddress)
        .then((response) => {
          console.debug("PortalView linking wallet with Ledger Step 1:", response)

          // this.startCheckingStatus(itemId)

          this.lastTxId = response.data.txId
          this.tx = TransactionLib.parseTransaction(response.data.cborHex)
          return this.walletApi.signTx(response.data.cborHex, false)
        })
        .then((witnessCbor) => {
          console.debug("PortalView linking wallet with Ledger Step 2:", witnessCbor)
          let witness = TransactionLib.parseWitnessSet(witnessCbor)
          this.tx = TransactionLib.addWitness(this.tx, witness)

          // we send the signed data to the Backend to 1) validate it
          // and 2) add the wallet address to the Discord profile
          // cbor: TransactionLib.serializeTransaction(this.tx)
          return axios
            .post(this.apiBaseURL + '/account/claimWallet2',
              {
                cbor: TransactionLib.serializeTransaction(this.tx)
              },
              {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
              })
        })
        .then((response) => {
          console.debug("PortalView linking wallet with Ledger step 3:", response)
          return axios
            .get(this.apiBaseURL + '/account/userInfo',
              {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
              }
            )
        })
        .then((response) => {
          console.debug("PortalView linking wallet with Ledger step 4:", response)
          // we try to recognize that a new wallet has been added compared to previous state
          let oldProfile = this.profile
          let newProfile = this.profile = response && response.data && response.data.id ? response.data : null

          if (!oldProfile || !newProfile) {
            throw new Error("profile could not be updated")
          }

          if ((!oldProfile.wallets && newProfile.wallets && newProfile.wallets.length > 0) ||
            oldProfile.wallets && newProfile.wallets && oldProfile.wallets.length < newProfile.wallets.length) {
            console.debug("PortalView linking wallet with Ledger successful")
            this.successMessage = "Wallet linked successfully with Ledger"
            this.linkedSuccessfully = true
          } else {
            throw new Error("wallets could not be updated")
          }
        })
        .catch((err) => {
          console.error("PortalView linking wallet with Ledger error:", err)

          this.linkingErrorMessage = err.response?.data?.message || err.message || err

          this.linkingErrorMessage = "Not successful: " + this.linkingErrorMessage
          this.showLinkingError = true
        })
        .finally(() => {
          this.linkingWallet = false
        });
    },
  },
}
</script>

<template>
  <v-container class="mt-10 pb-md-0 px-md-0 font-size-20" width="100%" dark>
    <header class="my-8">
      <!-- PROFILE box -->
      <v-card class="mb-0 pa-5 rounded-t-xl rounded-b-0" elevation="0" color="var(--color-aubergine)">
        <v-row class="d-flex">
          <v-col class="pa-2" style="position: relative;" cols="12" md="2">
            <v-avatar style="max-width: 100%; z-index: 2;" size="100%">
              <v-img v-if="profile && profile.id && profile.avatar"
                :src="`https://cdn.discordapp.com/avatars/${profile.id}/${profile.avatar}.png`"></v-img>
              <v-img v-else :src="require('@/assets/img/avatar_placeholder.png')"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="pa-2" cols="12" md="7">
            <p class="mt-3">The Mandrillz</p>
            <h3 class="mb-5">{{ profile && profile.username ? profile.username : "Unknown Broaarr" }}</h3>
          </v-col>
          <v-col class="pa-2" cols="12" md="3">
            <a v-if="!profile" class="text-decoration-none" href="/oauth2/authorization/discord">
              <v-btn class="mb-3 rounded-lg font-weight-bold" name="login-with-discord-button" elevation="0" height="60"
                color="var(--color-mandrillz-blue)" block :loading="fetchingLoginState" :disabled="fetchingLoginState">Login with Discord</v-btn>
            </a>
            <!-- <a class="text-decoration-none" href="/profile">
              <v-btn class="rounded-lg font-weight-bold" name="edit-profile-button" elevation="0" height="60"
                color="var(--color-aubergine-2)" block>My Profile</v-btn>
            </a> -->
            <template v-if="profile && walletAddress">
              <v-btn :disabled="isCurrentWalletLinked || linkingWallet"
                class="mt-2 rounded-lg font-weight-bold" name="link-wallet-button" elevation="0" height="60"
                color="var(--color-mandrillz-yellow)" @click="startLinkingWallet" block light>
                Link Wallet
              </v-btn>
              <v-switch class="ml-10" color="blue" v-model="useLedger" label="Use Ledger Linking"></v-switch>
            </template>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-lg-n13 mb-8 pa-5 rounded-t-0 rounded-b-xl" elevation="0" color="var(--color-aubergine-4)">
        <v-row class="d-flex">
          <v-col cols="12" md="9" offset-md="2">
            <v-btn v-if="showStarterPacks" class="font-weight-bold" name="profile-drop-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-packs-card', scrollOptions)" color="var(--color-text-light)" text>Token Pack</v-btn>
            <v-btn v-if="mintableItems && mintableItems.length > 0" class="font-weight-bold" name="profile-drop-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-drop-card-0', scrollOptions)" color="var(--color-text-light)" text>New Drops</v-btn>
            <v-btn class="font-weight-bold" name="profile-info-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-wallets-card', scrollOptions)" color="var(--color-text-light)" text>Wallets</v-btn>
            <v-btn class="font-weight-bold" name="profile-twitter-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-twitter-card', scrollOptions)" color="var(--color-text-light)" text>X</v-btn>
            <v-btn class="font-weight-bold" name="profile-discord-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-discord-card', scrollOptions)" color="var(--color-text-light)" text>Discord</v-btn>
            <!-- <v-btn lass="font-weight-bold" name="profile-missions-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-missions-card', scrollOptions)" color="var(--color-text-light)" text>Missions</v-btn> -->
            <!-- <v-btn class="font-weight-bold" name="profile-trophys-badges-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-trophys-card', scrollOptions)" color="var(--color-text-light)" text>Trophys & Badges</v-btn> -->
            <v-btn class="font-weight-bold" name="profile-token-balance-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-balance-card', scrollOptions)" color="var(--color-text-light)" text>Token Balance</v-btn>
            <v-btn class="font-weight-bold" name="profile-collections-button" elevation="0" height="30"
              @click="$vuetify.goTo('#profile-collections-card', scrollOptions)" color="var(--color-text-light)" text>Collections</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </header>

    <main class="portal-theme mt-8 mb-15">
        <!-- TOKEN PACK box -->
        <v-card v-if="showStarterPacks" id="profile-packs-card" class="mb-8 pa-5 rounded-xl" elevation="0" 
          color="var(--color-aubergine)" :disabled="!profile">
          <h1 class="mb-5">Free $DRILLZ Token Pack</h1>
          <h4 class="mb-5" v-if="!profile">Login with your Discord account first</h4>
          <section v-else>
            <v-alert v-if="showPackError" class="mt-5 rounded-lg text-wrap" type="error" color="white" text outlined
              elevation="0" dark>
              {{ packErrorMessage }}
            </v-alert>
            <section v-else-if="claimingTokenPacks" class="mt-5">
              <v-progress-linear class="rounded-lg" color="var(--color-green-light)" width="100%" height="10"
                indeterminate>
              </v-progress-linear>
              <v-alert class="mt-5 align-center rounded-lg font-dark text-uppercase text-caption text-wrap flex-wrap"
                type="info" color="var(--color-beige)" elevation="0" light>
                Claiming...
              </v-alert>
            </section>
            <section v-else-if="claimedSuccessfully" class="mt-5">
              <v-alert class="rounded-lg text-wrap" type="success" elevation="0" dark>
                {{ successMessage }}
              </v-alert>
            </section>
            
            <section v-if="isStarterPackClaimable && isStarterPackClaimable.length > 0" class="mt-5">
              Discord roles to get: {{ isStarterPackClaimable.map(a => a.role).join(", ") }}<br/>
              Tokens to receive: {{ isStarterPackClaimable.map(a => a.receivedTokens).reduce((a, b) => a + b, 0).toLocaleString() }}
            </section>
            <section v-else-if="!claimingTokenPacks" class="my-5 font-size-16">No token pack to claim for you currently</section>
          </section>

          <v-btn class="rounded-lg font-dark mt-5" name="claimStarterPackButton" elevation="0" height="60" @click="requestStarterPackClaim"
            :loading="checkingTokenPacks" :disabled="drillzBalance === null || claimingTokenPacks || checkingTokenPacks || !isStarterPackClaimable || isStarterPackClaimable.length <= 0"
            color="var(--color-mandrillz-yellow)" block light>Claim Now</v-btn>
        </v-card>

      <!-- Special Mint/Drop box -->
      <v-sheet v-for="(item, index) in mintableItems" :key="`mintable-item-${item.id}`" :id="`profile-drop-card-${index}`" class="mb-8 pa-5 rounded-xl" elevation="0" color="var(--color-aubergine)">
        <!-- <v-img class="nye-spinner" style="position: absolute; top: -50px; right: 15px" 
            width="100" height="100" :src="NYESpinner" />-->
        <h1 class="mb-5">{{ item.headline }}</h1>
        <v-row>
          <v-col cols="12" md="6">
            <v-img class="d-flex px-5 py-2 rounded-lg align-center justify-space-between"
              :src="item.image" color="var(--color-mandrillz-purple)" elevation="0"
              width="100%">
            </v-img>
          </v-col>
          <v-col class="" cols="12" md="6">
            <v-card class="mb-5 pa-5 rounded-lg" color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <h3 class="mb-2">{{ item.name }}</h3>
              <div class="font-size-16" v-html="item.description"></div>
            </v-card>
            <v-card class="d-flex px-5 py-2 mb-5 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">Drillz Token</div>
              <div class="d-flex justify-right align-center">
                <v-progress-circular v-if="walletAddress && drillzBalance === null" indeterminate></v-progress-circular>
                <v-img class="pl-2 mx-1" :src="DrillzLogoLight" width="40" />
                <h2 class="pl-2">{{ item.drillzTokens ? item.drillzTokens.toLocaleString() : '-' }}</h2>
              </div>
            </v-card>
            <v-card class="d-flex px-5 py-2 mb-5 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">ADA</div>
              <div class="d-flex justify-right align-center">
                <h2 class="pl-2">{{ item.workingAda ? item.workingAda.toLocaleString() : '-' }}</h2>
              </div>
            </v-card>
            <v-alert v-if="showError" class="mt-5 rounded-lg text-wrap" type="error" color="white" text outlined
              elevation="0" dark>
              {{ errorMessage }}
            </v-alert>
            <section v-else-if="loading || checkingStatus" class="mt-5">
              <v-progress-linear class="rounded-lg" color="var(--color-green-light)" width="100%" height="10"
                indeterminate>
              </v-progress-linear>
              <v-alert class="mt-5 align-center rounded-lg text-uppercase text-caption text-wrap flex-wrap"
                type="info" color="white" text outlined elevation="0" dark>
                {{ mintStatus ? (mintStatus == "Awaiting Payment Confirmation" ? mintStatus + " (can take 2-3 minutes) " :
                  mintStatus) : "Checking status" }} <a v-if="lastTxId" class="ml-auto"
                  :href="`https://cardanoscan.io/transaction/${lastTxId}`" target="_blank" light>Explorer link</a>
              </v-alert>
            </section>
            <section v-else-if="mintedSuccessfully" class="mt-5">
              <v-alert class="rounded-lg text-wrap" type="success" elevation="0" dark>
                {{ successMessage }} <a v-if="lastTxId" class="ml-auto"
                  :href="`https://cardanoscan.io/transaction/${lastTxId}`" target="_blank" dark>Explorer link</a>
              </v-alert>
            </section>

            <!-- Info box if not mintable -->
            <!-- <v-alert v-if="profile && profile.roles && !profile.roles.includes(item.allowedRole)" class="my-0 rounded-lg text-wrap" type="info" text outlined elevation="0" dark>
                You don't have the required Discord roles for this NFT to be mintable. 
            </v-alert> -->
            <!-- Info box if not mintable -->
            <v-alert v-if="!mintableStates[item.id]" 
                class="my-0 rounded-lg text-wrap" type="info" text outlined elevation="0" dark>
                  No mintable status yet, fetching...
              </v-alert>
            <v-alert v-else-if="!mintableStates[item.id].mintable && mintableStates[item.id].reason" 
              class="my-0 rounded-lg text-wrap" type="info" text outlined elevation="0" dark>
                {{ mintableStates[item.id].reason }}
            </v-alert>
            <!-- Mint button if mintable -->
            <v-btn v-else class="rounded-lg font-dark font-weight-bold" name="MintSpecialButton" elevation="0" height="60" @click="requestMinting(item.id)"
              :loading="fetchingMintableItems" :disabled="drillzBalance === null || loading || checkingStatus"
              color="white" block light>Mint Now!</v-btn>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- TROPHY BOARD box -->
      <!-- <v-card id="profile-trophys-card" class="mb-8 pa-5 rounded-lg" elevation="0" color="var(--color-aubergine)">
        <h1 class="mb-5">Trophy Board</h1>
        <v-btn-toggle v-model="selectedTrophyBoard" class="d-flex" color="transparent" background-color="transparent"
          active-class="horizontal-toggle-selector-active" borderless mandatory>
          <v-row class="d-flex horizontalToggleButtons">
            <v-col v-for="(value, key, index) in trophyBoards" :key="key" class="px-2 py-2" cols="6" md="4" lg="3">
              <v-btn class="rounded-lg font-weight-bold" :name="key" elevation="0" height="60"
                color="var(--color-aubergine-light)" block>
                {{ value.name }}
                <v-badge style="position: absolute; right: 0px; min-width: 25px;"
                  :content="trophyCount[index] ? trophyCount[index] : '-'"
                  class="text-size-fix font-weight-black font-dark" color="var(--color-beige)" inline overlap>
                </v-badge>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn class="rounded-lg font-weight-bold" name="portal-all-tropy-boards-button" elevation="0" height="60"
                color="var(--color-white-3)" @click.stop="showAllTrophyBoards = true" light block>All</v-btn>
            </v-col>
          </v-row>
        </v-btn-toggle>
      </v-card> -->

      <LinkedWalletsBox />
      <TwitterHandleBox />
      <DiscordRolesBox />

      <!-- YOUR TOKEN BALANCE box -->
      <v-card id="profile-balance-card" class="mb-8 pa-5 rounded-lg" elevation="0" color="var(--color-aubergine)"
        :disabled="!profile">
        <h1 class="mb-5">Your Token Balance</h1>
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="d-flex px-5 py-2 rounded-lg align-center justify-space-between"
              color="var(--color-mandrillz-purple)" elevation="0" width="100%">
              <div class="font-size-20">Token Balance</div>
              <div class="d-flex justify-right align-center">
                <v-progress-circular v-if="profile && totalDrillzBalance === null" indeterminate></v-progress-circular>
                <v-img class="pl-2 mx-1" :src="DrillzLogoLight" width="40" />
                <h2 class="pl-2">{{ totalDrillzBalance !== null ? totalDrillzBalance.toLocaleString() : '-' }}</h2>
              </div>
            </v-card>
          </v-col>
          <!-- <v-col class="" cols="12" md="6">
            <v-card class="d-flex px-5 py-2 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">Estimated earnings (per month)</div>
              <div class="d-flex justify-right align-center">
                <v-progress-circular v-if="estimatedEarnings === null" indeterminate></v-progress-circular>
                  <v-img class="ml-2" :src="DrillzLogoLight" width="40" />
                <h2 class="ml-2">{{ estimatedEarnings !== null ? estimatedEarnings : '-' }}</h2>
                <h2>&nbsp;</h2>
                <span class="font-size-16">(coming soon)</span>
              </div>
            </v-card>
          </v-col> -->
          <v-col class="" cols="12">
            <v-card class="px-5 py-2 rounded-lg" color="var(--color-mandrillz-purple)" elevation="0" width="100%">
              <v-img style="position: absolute; top: 15px; right: 15px" width="50" height="50" :src="DrillzToken3D" />
              <div class="d-flex align-center">
                <h3 class="d-inline-flex justify-center align-start">
                  Your $DRILLZ Token Balance
                </h3>
                <v-switch class="ml-10" color="blue" v-model="useBarChart" label="Bar Chart"></v-switch>
                <!-- <v-switch v-model="fillChart" class="mx-10" :disabled="useBarChart" label="Filled"></v-switch> -->
                <!-- <v-slider v-model="lineWidth" class="mt-5" :disabled="useBarChart || fillChart" label="Width" min="0.1" max="10" step="0.1" thumb-label></v-slider> -->
              </div>
              <div class="font-size-20">Total</div>
              <div style="width: 90%; position: relative;" class="mx-auto overflow-visible">
                <TokenBalanceGraph :use-bar-chart="useBarChart" :gradient="['#D9D9D9']" :fill="fillChart"
                  :line-width="lineWidth" />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <!-- YOUR COLLECTIONS box -->
      <v-card id="profile-collections-card" class="my-8 pa-5 rounded-lg" elevation="0" color="var(--color-aubergine)">
        <h1 class="mb-5">Your Collections</h1>
        <v-btn-toggle v-model="selectedPolicyId" class="d-flex" color="transparent" background-color="transparent"
          active-class="horizontal-toggle-selector-active" borderless mandatory>
          <v-row class="d-flex horizontalToggleButtons">
            <v-col v-for="(value, key, index) in policies" :key="key" class="px-2 py-2" cols="12" md="6" lg="3">
              <v-btn class="rounded-lg font-weight-bold" :name="key" elevation="0" height="60"
                color="var(--color-aubergine-light)" block>
                {{ value.name }}
                <v-badge style="position: absolute; right: 0px; min-width: 25px;"
                  :content="itemCount[index] ? itemCount[index] : '-'" class="text-size-fix font-weight-black font-dark"
                  color="var(--color-beige)" inline overlap>
                </v-badge>
              </v-btn>
            </v-col>
            <!-- <v-col>
                <v-btn class="rounded-lg font-weight-bold" name="portal-all-collections-button" elevation="0" height="60"
                  color="var(--color-aubergine-light)" @click.stop="showAllCollections = true" block>All</v-btn>
              </v-col> -->
          </v-row>
        </v-btn-toggle>
      </v-card>

      <!-- COLLECTION STATISTICS box -->
      <v-card class="my-8 pa-5 rounded-lg" elevation="0" color="var(--color-aubergine)">
        <v-row>
          <v-col class="py-4" cols="12" md="6">
            <v-card class="d-flex px-5 py-2 mb-5 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">Total Volume</div>
              <div class="d-flex align-center">
                <v-progress-circular v-if="walletAddress && policyVolume === null" indeterminate></v-progress-circular>
                <h2 class="pl-2">{{ walletAddress && policyVolume !== null && policyVolume !== -1 ? policyVolume.toLocaleString() : '-' }}
                </h2>
              </div>
            </v-card>
            <v-card class="d-flex px-5 py-2 mb-5 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">Owners</div>
              <div class="d-flex align-center">
                <v-progress-circular v-if="walletAddress && policyOwners === null" indeterminate></v-progress-circular>
                <h2 class="pl-2">{{ walletAddress && policyOwners !== null && policyOwners !== -1 ? policyOwners : '-' }}
                </h2>
              </div>
            </v-card>
            <v-card class="d-flex px-5 py-2 rounded-lg align-center justify-space-between"
              color="var(--color-aubergine-mute)" elevation="0" width="100%">
              <div class="font-size-20">Floor Price</div>
              <div class="d-flex align-center">
                <v-progress-circular v-if="walletAddress && policyFloorPrice === null"
                  indeterminate></v-progress-circular>
                <h2 class="pl-3">{{ walletAddress && policyFloorPrice !== null && policyFloorPrice !== -1 ?
                  policyFloorPrice : '-' }}</h2>
              </div>
            </v-card>
          </v-col>
          <v-col class="py-4" cols="12" md="6">
            <v-card class="pa-5 rounded-lg" color="var(--color-aubergine-mute)" elevation="0" width="100%" height="440">
              <h3 class="mb-3">{{ policyName }}</h3>
              <div class="font-size-16" v-html="policyDescription">
              </div>
              <!-- COPY Policy ID button -->
              <v-btn v-if="selectedPolicy" style="position: absolute; bottom: 20px; right: 20px;"
                class="ml-auto mt-auto rounded-lg font-dark text-caption" @click="copyPolicyId"
                color="var(--color-aubergine-light)" height="40" depressed large>
                Policy ID {{ selectedPolicy.slice(0, 8) }}...
                <v-icon class="font-dark" light right>mdi-content-copy</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <!-- COLLECTION GRIDS -->
      <section v-if="profile && walletAddress" class="my-8">
        <!-- <v-row v-if="false" class="mb-5">
            <v-col cols="12">
              <v-progress-linear class="rounded-lg" :active="loading" color="var(--color-green-light)" indeterminate
                height="10">
              </v-progress-linear>
            </v-col>
          </v-row> -->

        <!-- multiple GRIDS -->
        <CollectionGrid v-for="(value, key, index) in policies" 
          v-show="showAllCollections || selectedPolicyId == index"
          :key="`portalGrid-${index}`" 
          :policy-id="key" 
          :name="`portalCollectionGrid-${index}`"
          :collection-urls="profile.wallets.map(a => walletCollectionURLPrefix + a.stakeAddressHash + '/' + key)" 
          :auto-reset="false"
          :stylingClass="'portal-grid'" 
          :name-key="'assetName'"
          :placeholder-data="value.placeholderData" 
          @numberOfItems="saveNumberOfItems" />
      </section>
    </main>
  </v-container>
</template>

<style>
:root {
  --color-portal-card-background: #5B5B6B;
}

@media (min-width: 960px) {
  .col-md-2-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.portal-theme .row {
  margin: -16px;
}

.v-application .portal-theme .primary--text {
  color: var(--color-text-light) !important;
}

.portal-theme .nye-spinner {
  animation: rotation 10s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.portal-theme .portal-grid,
.portal-theme .portal-grid .hiddenDetailsContainer {
  background-color: var(--color-aubergine);
  color: var(--color-text-light);
}

.portal-theme .portal-grid .hiddenDetailsContainer .hiddenBaseCard {
  background-color: var(--color-aubergine-mute);
}

.portal-theme .portal-grid .traitList:nth-child(odd) {
  background: var(--color-aubergine-light);
}

.portal-theme .portal-grid .infoCard,
.portal-theme .portal-grid .actionCard {
  background-color: var(--color-portal-card-background);
  color: var(--color-text-light);
}

.portal-theme .portal-grid .actionCard .actionButton {
  background-color: var(--color-text-2);
}

.portal-theme .portal-grid .assetNumberBox {
  background-color: var(--color-mandrillz-purple);
  color: white !important;
}

.horizontal-toggle-selector-active {
  background: var(--color-mandrillz-purple) !important;
}

.portal-theme .horizontal-toggle-selector-active,
.portal-theme .horizontal-toggle-selector-active * {
  color: var(--color-beige-light) !important;
}

.portal-theme .horizontal-toggle-selector-active .v-badge,
.portal-theme .horizontal-toggle-selector-active .v-badge * {
  color: var(--color-text-dark) !important;
}

.portal-theme .v-badge--icon .v-badge__badge {
  padding: 4px 0px;
}

.portal-theme .horizontalToggleButtons .v-badge *,
.portal-theme .collectionItemContainer .v-badge * {
  font-size: 16px;
  height: unset;
}

.portal-theme .collectionItemContainer.isHighlightedItem {
  background-color: var(--color-mandrillz-yellow);
  box-shadow: 0px 0px 20px 3px var(--color-mandrillz-yellow) !important;
}

.portal-theme .collectionItemContainer .sampleText {
  color: var(--color-mandrillz-yellow);
}
</style>